import React from 'react';
import { Form, Input, Select } from 'antd';
import './BalancaComercialForm.scss';

const { Option } = Select;

const BalancaComercialForm = () => {
  return <div className="BalancaComercialForm">

    <Form.Item
      name="date"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      name="balanca_comercial_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <h3>EXPORTAÇÕES</h3>
    <h4>1</h4>
    <div className="line">
      <Form.Item
        label="Mês"
        name="balanca_comercial_exportacao1_mes"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Ano"
        name="balanca_comercial_exportacao1_ano"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="balanca_comercial_exportacao1_variacao"
      >
        <Input />
      </Form.Item>
      <Form.Item name="balanca_comercial_exportacao1_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor"
        name="balanca_comercial_exportacao1_valor"
      >
        <Input />
      </Form.Item>
    </div>

    <h4>2</h4>
    <div className="line">
      <Form.Item
        label="Mês"
        name="balanca_comercial_exportacao2_mes"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Ano"
        name="balanca_comercial_exportacao2_ano"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="balanca_comercial_exportacao2_variacao"
      >
        <Input />
      </Form.Item>
      <Form.Item name="balanca_comercial_exportacao2_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor"
        name="balanca_comercial_exportacao2_valor"
      >
        <Input />
      </Form.Item>
    </div>







    <h3>IMPORTAÇÕES</h3>
    <h4>1</h4>
    <div className="line">
      <Form.Item
        label="Mês"
        name="balanca_comercial_importacao1_mes"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Ano"
        name="balanca_comercial_importacao1_ano"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="balanca_comercial_importacao1_variacao"
      >
        <Input />
      </Form.Item>
      <Form.Item name="balanca_comercial_importacao1_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor"
        name="balanca_comercial_importacao1_valor"
      >
        <Input />
      </Form.Item>
    </div>

    <h4>2</h4>
    <div className="line">
      <Form.Item
        label="Mês"
        name="balanca_comercial_importacao2_mes"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Ano"
        name="balanca_comercial_importacao2_ano"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="balanca_comercial_importacao2_variacao"
      >
        <Input />
      </Form.Item>
      <Form.Item name="balanca_comercial_importacao2_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor"
        name="balanca_comercial_importacao2_valor"
      >
        <Input />
      </Form.Item>
    </div>








    <h3>BALANÇA COMERCIAL</h3>
    <h4>1</h4>
    <div className="line">
      <Form.Item
        label="Mês"
        name="balanca_comercial_balanca1_mes"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Ano"
        name="balanca_comercial_balanca1_ano"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="balanca_comercial_balanca1_variacao"
      >
        <Input />
      </Form.Item>
      <Form.Item name="balanca_comercial_balanca1_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor"
        name="balanca_comercial_balanca1_valor"
      >
        <Input />
      </Form.Item>
    </div>

    <h4>2</h4>
    <div className="line">
      <Form.Item
        label="Mês"
        name="balanca_comercial_balanca2_mes"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Ano"
        name="balanca_comercial_balanca2_ano"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Variação %"
        name="balanca_comercial_balanca2_variacao"
      >
        <Input />
      </Form.Item>
      <Form.Item name="balanca_comercial_balanca2_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Valor"
        name="balanca_comercial_balanca2_valor"
      >
        <Input />
      </Form.Item>
    </div>


  </div>
};
export default BalancaComercialForm;