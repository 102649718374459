import React, { useCallback, useEffect, useState } from 'react';
import { Button, message, Table, Tag } from 'antd';
import './ListarAssessores.scss';
import { useNavigate } from 'react-router-dom';
import { listUsersRequests } from '../../requests';

function compararBooleanos(a, b) {
  // Se 'a' for falso e 'b' for verdadeiro, 'a' deve vir antes de 'b'
  if (a === false && b === true) {
    return -1;
  }
  // Se 'a' for verdadeiro e 'b' for falso, 'b' deve vir antes de 'a'
  else if (a === true && b === false) {
    return 1;
  }
  // Caso contrário, a ordem não importa para valores booleanos
  else {
    return 0;
  }
}

function converterDataStringParaDate(dataString) {
  // Separar a string da data em dia, mês e ano
  var partes = dataString.split('/');
  // O formato padrão de entrada de Date é ano/mês/dia
  return new Date(partes[2], partes[1] - 1, partes[0]);
}

const columns = [
  {
    title: 'Nome',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Username',
    dataIndex: 'username',
    sorter: (a, b) => a.username.localeCompare(b.username),
  },
  {
    title: 'Escritório',
    dataIndex: 'logoImg',
    sorter: (a, b) => (a.logoImg || '').localeCompare(b.logoImg || ''),
    render: (text) => {
      switch(text) {
        case 'the-hill':
          return 'The Hill';
        case 'hold':
          return 'Hold';
        case 'amg':
          return 'AMG Capital';
        case 'seven':
          return 'Seven Investors';
        case 'criteria':
          return 'Criteria Investimentos';
        case 'renata-invest':
          return 'Renata Gonçalves Invest';
        case 'jb3':
          return 'JB3 Investimentos';
        case 'ateena':
          return 'Ateena Novos Negócios';
        case 'fictor-Agro':
          return 'Fictor Agro';
        case 'verbum':
          return 'Verbum Capital';
        default:
          return text;
      }
    }
  },
  {
    title: 'QR Code',
    dataIndex: 'qrcode',
    sorter: (a, b) => compararBooleanos(a.qrcode, b.qrcode),
    render: (qrcode) => qrcode ? <Tag color="geekblue">Sim</Tag> : <Tag color="volcano">Não</Tag>,
  },
  {
    title: 'Inclusão',
    dataIndex: 'date',
    sorter: (a, b) => converterDataStringParaDate(a.date) - converterDataStringParaDate(b.date),
  },
  {
    title: 'Ativo',
    dataIndex: 'active',
    sorter: (a, b) => compararBooleanos(a.active, b.active),
    render: (active) => active ? <Tag color="geekblue">Sim</Tag> : <Tag color="volcano">Não</Tag>,
  },
];

function ListarAssessores() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalActive, setTotalActive] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = useCallback(async () => {
    // Função para fazer a solicitação GET
    try {
      const response = await listUsersRequests.listUsers();
      console.log(response);
      if (!response.data.error) {
        const { content } = response.data;
        setData(content.map(v => ({ ...v, key: v.id})));
        setTotal(content.length);
        setTotalActive(content.reduce((amount, item) => (amount + (item.active ? 1 : 0)), 0));
      }
    } catch (error) {
      setData([]); // Limpa os dados caso ocorra um erro
      messageApi.open({
        type: 'error',
        content: error.response?.data?.message || error.message,
      });
    }
  }, [messageApi]);

  useEffect(() => {
    fetchData(); // Chama a função de busca quando o componente é montado
  }, [fetchData]);

  return (
    <div className="ListarAssessores">
      {contextHolder}



      {!!data && <div>
      {
//      <div className="header">
//        <Button onClick={() => navigate('/assessores/novo')}>Adicionar novo</Button>
//      </div>

//      data.map(a => {
//          const date = a.date;
//          const [day, month, year] = date.split('/');
//          const formattedDate = `${year}-${month}-${day}  00:00:00`;
//          return <div>{`('${a.id}', '${a.name}', '${a.username}', ${a.namePdf ? ("'" + a.namePdf + "'") : null}, ${a.logoImg ? ("'" + a.logoImg + "'") : null}, ${a.active}, '${formattedDate}', '${formattedDate}'),`}</div>;
//        }
//      )

      }

      </div>}


      <Table columns={columns} dataSource={data} pagination={false} />
      <br />
      <br />
      {!!total && <div>Total: {total}</div>}
      {!!totalActive && <div>Total ativo: {totalActive}</div>}
    </div>
  )
}

export default ListarAssessores;
