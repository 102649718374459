import React from 'react';
import { Button, Form, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './JornalForm.scss';
import { dummyRequest, normFile } from '../../../utils/upload';

const JornalForm = () => {
  return <div className="JornalForm">

    <h4>Página 1</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem1"
        label="Imagem 1"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem1"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>
    </div>

    <h4>Página 2</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem2"
        label="Imagem 2"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem2"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="jornal_qrcode2_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="jornal_qrcode2_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 3</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem3"
        label="Imagem 3"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem3"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="jornal_qrcode3_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="jornal_qrcode3_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 4</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem4"
        label="Imagem 4"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem4"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="jornal_qrcode4_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="jornal_qrcode4_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 5</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem5"
        label="Imagem 5"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem5"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="jornal_qrcode5_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="jornal_qrcode5_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 6</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem6"
        label="Imagem 6"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem6"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="jornal_qrcode6_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="jornal_qrcode6_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 7</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem7"
        label="Imagem 7"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem7"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="jornal_qrcode7_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="jornal_qrcode7_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h4>Página 8</h4>
    <div className="line">
      <Form.Item
        name="jornal_imagem8"
        label="Imagem 8"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Upload
          name="jornal_imagem8"
          customRequest={dummyRequest}
          accept=".jpg,.jpeg,.png,.gif"
          listType="picture"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Selecionar Imagem</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="jornal_qrcode8_x"
        label="QRCode posição horizontal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="jornal_qrcode8_y"
        label="QRCode posição vertical"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

  </div>
};
export default JornalForm;