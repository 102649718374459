import React from 'react';
import { Form, Input, Select } from 'antd';

import './MetalCommoditiesForm.scss';

const { Option } = Select;

const MetalCommoditiesForm = () => {

  return <div className="MetalCommoditiesForm">

    <div className="line-full">
      <Form.Item name="metal_commodities_modelo" label="Modelo" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="feed">Feed</Option>
          <Option value="stories">Stories</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="metal_commodities_date"
        label="Data referência"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="metal_commodities_horario_brasilia"
        label="Horário de Brasília"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h3>Ouro</h3>
    <div className="line">
      <Form.Item
        name="metal_commodities_ouro_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="metal_commodities_ouro_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="metal_commodities_ouro_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item name="metal_commodities_ouro_termometro" label="Termômetro" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="fear">Fear</Option>
          <Option value="neutral">Neutral</Option>
          <Option value="greed">Greed</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="metal_commodities_ouro_mes"
        label="Mês"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h3>Prata</h3>
    <div className="line">
      <Form.Item
        name="metal_commodities_prata_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="metal_commodities_prata_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="metal_commodities_prata_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item name="metal_commodities_prata_termometro" label="Termômetro" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="fear">Fear</Option>
          <Option value="neutral">Neutral</Option>
          <Option value="greed">Greed</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="metal_commodities_prata_mes"
        label="Mês"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <h3>Cobre</h3>
    <div className="line">
      <Form.Item
        name="metal_commodities_cobre_valor"
        label="Valor"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="metal_commodities_cobre_variacao"
        label="Variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="metal_commodities_cobre_variacao_status" label="Variação status" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item name="metal_commodities_cobre_termometro" label="Termômetro" className="select" rules={[{ required: true }]}>
        <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
          <Option value="fear">Fear</Option>
          <Option value="neutral">Neutral</Option>
          <Option value="greed">Greed</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="metal_commodities_cobre_mes"
        label="Mês"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


  </div>
};
export default MetalCommoditiesForm;