import React, { useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Input, message, Select, Spin } from 'antd';

const { Option } = Select;

function GerenciarAssessores() {
  const [form] = Form.useForm();
  const [currentForm, setCurrentForm] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [version, setVersion] = useState(Math.random());
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    console.log('Form Values:', values);
  };

  const generateSlug = (name) => {
    return name
      .normalize('NFD')
      .replace(/[̀-ͯ]/g, '') // Remove accents
      .replace(/ç/g, 'c')
      .replace(/[^a-zA-Z0-9\-_]/g, '') // Remove invalid characters
      .replace(/\s+/g, '') // Remove spaces between words
      .replace(/--+/g, '-') // Replace multiple '-' with a single '-'
      .replace(/^-+|-+$/g, ''); // Remove leading and trailing '-'
  };

  const onNameChange = (e) => {
    const name = e.target.value;
    const slug = generateSlug(name); // Remove spaces
    form.setFieldsValue({ slug });
  };


  return (
    <div>
      {contextHolder}
      <Spin tip="Carregando" spinning={loading}>
        <Form
          form={form}
          name="basic"
          layout="vertical"
//          initialValues={initialValues}
          initialValues={{ company: 'nenhum' }}
          autoComplete="off"
        >


        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter the advisor name!' }]}
        >
          <Input onChange={onNameChange} />
        </Form.Item>

        <Form.Item
          label="Slug (Nome no arquivo)"
          name="slug"
          rules={[{ required: true, message: 'Please enter a slug!' }]}
          help="Gerado automaticamente. Alterar apenas caso haja mais de um usuário com o mesmo nome gerado"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Username (@)"
          name="username"
          rules={[{ required: true, message: 'Please enter the username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nome jornal"
          name="namePdf"
          help="Usado apenas para assessores com siglas como CFP®, CEA®, CGA, CGE, etc"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Escritório"
          name="company"
          rules={[{ required: true, message: 'Campo obrigatório!' }]}
        >
          <Select>
            <Option value="nenhum">Nenhum</Option>
            <Option value="amg">AMG Capital</Option>
            <Option value="ateena">Ateena</Option>
            <Option value="criteria">Criteria</Option>
            <Option value="hold">Hold investimentos</Option>
            <Option value="jb3">JB3</Option>
            <Option value="renata-invest">Renata Gonçalves Invest</Option>
            <Option value="sniperb3s">Sniperb3s</Option>
            <Option value="the-hill">The Hill</Option>
            <Option value="verbum">Verbum Capital</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="active"
          valuePropName="checked"
        >
          <Checkbox>Active</Checkbox>
        </Form.Item>



        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>




        </Form>

      </Spin>
    </div>
  );
}

export default GerenciarAssessores;
