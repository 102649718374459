import React from 'react';
import { Form, Input, Select } from 'antd';
import './IpcaForm.scss';

const { Option } = Select;

const IpcaForm = () => {
  return <div className="IpcaForm">

    <Form.Item
      name="ipca_model"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
      style={{ minWidth: 260 }}
      label="Modelo"
    >
      <Select rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Option value="stories">Story</Option>
        <Option value="feed">Feed</Option>
      </Select>
    </Form.Item>


    <Form.Item
      name="ipca_data"
      label="Data"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>


    <Form.Item
      name="ipca_hora"
      label="Hora"
      rules={[{ required: true, message: 'Campo obrigatório!' }]}
    >
      <Input />
    </Form.Item>





    <div className="line">
      <Form.Item
        name="ipca_principal_mes"
        label="Mês principal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="ipca_principal_nome"
        label="Nome principal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_principal_status" label="Status principal" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_principal_variacao"
        label="Variação principal"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_secundario_mes"
        label="Mês secundário"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="ipca_secundario_nome"
        label="Nome secundário"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_secundario_status" label="Status secundário" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_secundario_variacao"
        label="Variação secundário"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>






    <div className="line">
      <Form.Item
        name="ipca_month1_value"
        label="Mês 1"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_month1_status" label="Mês 1 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_month1_variacao"
        label="Mês 1 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_month2_value"
        label="Mês 2"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_month2_status" label="Mês 2 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_month2_variacao"
        label="Mês 2 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_month3_value"
        label="Mês 3"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_month3_status" label="Mês 3 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_month3_variacao"
        label="Mês 3 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>






    <div className="line">
      <Form.Item
        name="ipca_year1_value"
        label="Ano 1"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_year1_status" label="Ano 1 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_year1_variacao"
        label="Ano 1 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_year2_value"
        label="Ano 2"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_year2_status" label="Ano 2 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_year2_variacao"
        label="Ano 2 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_year3_value"
        label="Ano 3"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_year3_status" label="Ano 3 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_year3_variacao"
        label="Ano 3 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>


    <div className="line">
      <Form.Item
        name="ipca_setor1_nome"
        label="Setor 1 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_setor1_status" label="Setor 1 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_setor1_variacao"
        label="Setor 1 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_setor2_nome"
        label="Setor 2 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_setor2_status" label="Setor 2 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_setor2_variacao"
        label="Setor 2 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_setor3_nome"
        label="Setor 3 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_setor3_status" label="Setor 3 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_setor3_variacao"
        label="Setor 3 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>

    <div className="line">
      <Form.Item
        name="ipca_setor4_nome"
        label="Setor 4 nome"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="ipca_setor4_status" label="Setor 4 status" className="select" rules={[{ required: true, message: 'Campo obrigatório!' }]}>
        <Select>
          <Option value="aumento">Aumento</Option>
          <Option value="diminuicao">Diminuição</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="ipca_setor4_variacao"
        label="Setor 4 variação"
        rules={[{ required: true, message: 'Campo obrigatório!' }]}
      >
        <Input />
      </Form.Item>
    </div>





  </div>
};
export default IpcaForm;